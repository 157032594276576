
import Vue, { PropType } from 'vue';

// @ts-ignore
// import { isMobile } from 'mobile-device-detect';
import ScrollContainer from '@/components/lh-ui/ScrollContainer/ScrollContainer.vue';

import IconCookingMethods from '~/components/filter/DropDownList/icons/IconCookingMethods.vue';
import IconDishes from '~/components/filter/DropDownList/icons/IconDishes.vue';
import IconMenus from '~/components/filter/DropDownList/icons/IconMenus.vue';
import IconCuisines from '~/components/filter/DropDownList/icons/IconCuisines.vue';
import IconIncludedIngredients
  from '~/components/filter/DropDownList/icons/IconIncludedIngredients.vue';
import IconExcludedIngredients
  from '~/components/filter/DropDownList/icons/IconExcludedIngredients.vue';
import IconTime from '~/components/filter/DropDownList/icons/IconTime.vue';
import { capitalize } from '~/utils/capitalize';

export default Vue.extend({
  name: 'DropDownList',

  scrollContainerOptions: {
    wheelPropagation: false,
  },

  components: {
    IconTime,
    IconExcludedIngredients,
    IconIncludedIngredients,
    IconCuisines,
    IconMenus,
    IconDishes,
    IconCookingMethods,
    ScrollContainer,
  },

  inject: {
    media: {
      default () {
        return () => null;
      },
    },
  },

  props: {
    type: {
      type: String as PropType<string>,
      required: true,
    },
    title: {
      type: String as PropType<string>,
      required: true,
    },

    isOpen: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    showIcon: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },

  computed: {
    mediaQuery (): 'mobile' | 'tablet' | 'desktop' | null {
      // @ts-ignore
      if (this.media) {
        // @ts-ignore
        return this.media();
      }

      return null;
    },

    // isMobile (): boolean {
    //   return isMobile;
    // },

    listContainer () {
      // return !isMobile ? ScrollContainer : 'div';
      // @ts-ignore
      return this.mediaQuery === 'desktop'
        ? ScrollContainer
        : 'div';
    },

    componentName (): string {
      // @ts-ignore
      return 'Icon' + capitalize(this.type);
    },
  },
});
