<template>
  <!--  Переделал в обычный компонент, т.к. не нашел способа пробросить key, -->
  <!--  иначе не работает <transition-group>-->
  <label
    ref="el"
    class="input__label"
    :class="{
      'no-transition': !isFilterAnimate,
      'input__label--current': isTransition && (type === 'checkbox'),
      'input__label--show': isAnimateColor && (type === 'checkbox'),
      'input__label--opacity': isAnimateOpacity && isTablet && (type === 'checkbox'),
    }"
  >
    <input
      class="input__item"
      type="checkbox"
      :value="value"
      :class="{ [`input__item--checked`]: checked }"
      @change="handler"
    >
    <span class="input__icon">{{ label }}</span>
  </label>
</template>

<script>
export default {
  name: 'TheCheckbox',

  props: {
    label: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
      required: true,
    },
    isLockAnimation: {
      type: Boolean,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    isTablet: {
      type: Boolean,
      required: true,
    },
    type: {
      type: String,
      validator: value => ['checkbox', 'radio'].includes(value),
      default: 'checkbox',
    },
    activatedHandler: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      isTransition: false,
      isAnimateColor: false,
      isAnimateOpacity: false,
    };
  },
  computed: {
    isFilterAnimate () {
      return this.$store.state.filter.isAnimate;
    },
  },
  watch: {
    activatedHandler (newValue) {
      if (newValue) {
        this.handler();
      }
    },
  },
  mounted () {
    // console.log(this.label, this.value, this.isLockAnimation);
    this.isAnimateColor = this.checked;
    this.$refs.el.addEventListener('transitionend', (e) => {
      if (this.isTablet) {
        if (e.propertyName === 'transform' && this.isTransition) {
          this.isTransition = false;
        }
      } else {
        this.isTransition = false;
      }
    });
  },
  updated () {
    if (this.isLockAnimation && this.isTablet) {
      return;
    }
    this.isAnimateColor = this.checked;
  },
  methods: {
    animateColorTumbler () {
      this.isAnimateColor = !this.isAnimateColor;
    },
    animateOpacityTumbler () {
      this.isAnimateOpacity = !this.isAnimateOpacity;
    },
    handler () {
      const vm = this;
      vm.$emit('start', true);
      vm.animateColorTumbler();
      this.isTransition = !!this.isTablet;
      vm.$emit('change', vm.animate);
    },
    animateOpacityTimeouted () {
      return new Promise((resolve) => {
        setTimeout(() => {
          this.animateOpacityTumbler();
          resolve();
        }, 300);
      });
    },
    animate (cb) {
      const vm = this;
      if (this.checked) {
        return new Promise((resolve) => {
          vm.animateOpacityTumbler();
          setTimeout(() => {
            cb();
            resolve();
          }, 100);
        })
          .then(() => vm.animateOpacityTimeouted());
      } else {
        return new Promise((resolve) => {
          setTimeout(() => {
            vm.animateOpacityTumbler();
            setTimeout(resolve, 100);
          }, 200);
        })
          .then(() => {
            cb();
            return vm.animateOpacityTimeouted();
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$borderColor: #969698;
$activeColor: #3A74F6;
$textColor: black;
$hoverColor: #E1EAFD;
$itemColor: #F3F5FC;
.no-transition {
  transition: none!important;
  * {
    transition: none!important;
  }
}
.input__label {
  background-color: $itemColor;
  padding: 7px 12px;
  @include fontNormalText(true);
  font-family: $mainFont;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  border-radius: 2px;
  color: black;
  position: relative;

  display: block;
  user-select: none;

  transition: background-color 0.3s ease, color 0.3s linear, opacity 0.3s linear;
  opacity: 1;
  z-index: 3;

  @include big-tablet() {
    max-width: unset;
    font-size: 12px;
    line-height: 18px;
  }

  @include big-tablet {
    @include hover {
      background-color: $hoverColor;
      cursor: pointer;
    }
  }

  &:focus-within {
    outline-color: #E59700;
    outline: 2px solid -webkit-focus-ring-color;
  }

  .using-mouse & {
    &:focus-within {
      outline: none;
    }
  }
  &--show {
    background-color: $linkColor;
    color: white;

    @include big-tablet {
      @include hover {
        background-color: #264EB1;
      }
    }
  }
  &--opacity {
    opacity: 0;
  }
  &--current {
    z-index: 1!important;

    @include big-tablet {
      background-color: $linkColor;
      color: white;

      @include hover {
        background-color: #264EB1;
      }
    }
  }
}

.input__item {
  width: 1px;
  height: 1px;
  overflow: hidden;
  display: none;
  clip: rect(0 0 0 0);

  position: absolute;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  outline: none;

  &--checked + .input__icon {
    &--checkbox {
      background-color: $activeColor;
      border-color: $activeColor;
    }

    &--radio {
      border-color: $activeColor;

      &::before {
        content: '';
        position: absolute;

        top: calc(50% - 6px);
        left: calc(50% - 6px);

        width: 12px;
        height: 12px;

        border-radius: 50%;
        background-color: $activeColor;
      }
    }
  }
}

.input__icon {
  &--checkbox {
    width: 16px;
    height: 16px;

    border: 1px solid $borderColor;
    border-radius: 2px;
  }

  &--radio {
    width: 20px;
    height: 20px;
    margin-right: 14px;

    border-radius: 50%;
    border: 2px solid $borderColor;
  }
}
</style>
