import Vue, { PropType } from 'vue';
import { renderHTMLParser } from '~/utils/content/default/content-parser';
import { renderGutenbergParser } from '~/utils/content/gutenberg/content-parser';

export default Vue.extend({
  name: 'TheContent',
  functional: true,

  props: {
    elem: {
      type: Object as PropType<TContent>,
      required: true,
    },
    // Ленивая загрузка картинок и айфремов. При гидратации может работать некорректно.
    // Нужно перерендерить компонент на фронте
    lazyLoadingEnabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    // Сейсас используется только для форм подписки, чтобы организовать внутренние отступы для элемента
    location: {
      type: String as PropType<string>,
      default: null,
    },
  },

  render (h, context) {
    const {
      props: {
        elem,
        lazyLoadingEnabled,
        location,
      },
    } = context;

    const renderByType: Record<string, Function> = {
      gutenberg_parser: renderGutenbergParser,
      html_parser: renderHTMLParser,
    };
    const currentRender = renderByType[elem.parser_type ?? 'html_parser'];

    if (currentRender) {
      return currentRender(
        elem,
        h,
        context,
        lazyLoadingEnabled,
        location,
      );
    } else {
      // eslint-disable-next-line no-console
      console.error(`Not found parser for component: ${elem.type} with parser type: ${elem.parser_type}`);
      // @ts-ignore
      return context._v('');
    }
  },
});
